

















































import { Component, Mixins } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import { NotificationMixin } from '../mixins';

@Component({
  name: 'Verify',
  methods: {
    ...mapActions('auth', ['login']),
  },
})
export default class Verify extends Mixins(NotificationMixin) {
  passwordHint = {
    content: `
                <span>Must contain:</span>
                <ul>
                    <li>At least 8 characters</li>
                    <li>At least 1 uppercase character</li>
                    <li>At least 1 lowercase character</li>
                    <li>At least 1 number</li>
                </ul>
                `,
    html: true,
  };

  user = {
    password: '',
    confirmPassword: '',
  };

  submit() {
    const { password, confirmPassword } = this.user;
    const token = this.$route.params.token;
    return this.$store
      .dispatch('auth/verify', { token, password, confirmPassword })
      .then(() => {
        this.notifySuccess('Successfully verified user. Please login.');
        this.$router.push({ name: 'login' });
      })
      .catch((err) => {
        const errMsg = err.message || 'Failed to set password.';
        this.notifyError(errMsg);
      });

    // const { username } = this.user
    // return this.$store.dispatch('auth/resend', { username }).then(() => {
    //     this.notifySuccess('Please check your email')
    //     this.$router.push({ name: 'login' })
    // }).catch((err) => {
    //     // TODO: update this
    //     const errMsg = err.message || 'Failed to resend verification link'
    //     this.notifyError(errMsg)
    // })
  }
}
